\<template>
    <div class="page">
        <banner></banner>
        <div class="tuijian-wrap w-1300">
            <div class="center-text">
                <p></p>
                <span>行业展会排列</span>
            </div>
            <div class="tuijian-list">
                <div class="tuijian-item" v-for="(item,index) in ggList" :key="index">
                    <div class="tuijian-top">
                        <div class="tuijian-text">
                            <div class="tuijian-p">{{item.name}}</div>
                            <div class="tuijian-p1" v-html="delLabel(item.content)"></div>
                        </div>
                    </div>
                    <div class="tuijian-bottom">
                        <!-- <div class="tuijian-bt"  @click="goTo('/huizhan_introduce',{id:item.id})">平台简介</div> -->
                        <div class="tuijian-bt"  @click="goTo('/recom',{id:item.id,huiz:1,hzy_id:$route.query.hzy_id})">简介</div>
                        <div class="tuijian-bt" @click="goTo('/enter',{id:item.plate_id,hzy_id:$route.query.hzy_id})">申请入驻</div>
                    </div>
                </div>
            </div>

            <div class="pingtai">
                <div class="pingtai-text">
                    <div class="pingtai-p">{{gg[0]?gg[0].title:''}}</div>
                    <div class="pingtai-p1">{{gg[0]?gg[0].content:''}}</div>
                </div>
                <div class="pingtai-list">
                    <div class="pingtai-item">
                        <img :src="gg[1]?gg[1].pic_url:require('../../../assets/images/kjdspt5.png')" alt="">
                        <div class="pingtai-item-p">{{gg[1]?gg[1].title:''}}</div>
                        <div class="pingtai-item-p1">{{gg[1]?gg[1].content:''}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="gg[2]?gg[2].pic_url:require('../../../assets/images/kjdspt5.png')" alt="">
                        <div class="pingtai-item-p">{{gg[2]?gg[2].title:''}}</div>
                        <div class="pingtai-item-p1">{{gg[2]?gg[2].content:''}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="gg[3]?gg[3].pic_url:require('../../../assets/images/kjdspt5.png')" alt="">
                        <div class="pingtai-item-p">{{gg[3]?gg[3].title:''}}</div>
                        <div class="pingtai-item-p1">{{gg[3]?gg[3].content:''}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="gg[4]?gg[4].pic_url:require('../../../assets/images/kjdspt5.png')" alt="">
                        <div class="pingtai-item-p">{{gg[4]?gg[4].title:''}}</div>
                        <div class="pingtai-item-p1">{{gg[4]?gg[4].content:''}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="gg[5]?gg[5].pic_url:require('../../../assets/images/kjdspt5.png')" alt="">
                        <div class="pingtai-item-p">{{gg[5]?gg[5].title:''}}</div>
                        <div class="pingtai-item-p1">{{gg[5]?gg[5].content:''}}</div>
                    </div>
                </div>
            </div>

            <div class="center-text">
                <p></p>
                <span>国家分类</span>
            </div>
            
            <div class="state-wrap">
                <div class="state" v-for="(item,index) in guojiaList" :key="index">
                    <div class="state-list">
                        <div class="state-item-text">{{item.name}}</div>
                        <div class="state-item" v-for="(item1,index1) in item.cs.slice(0,6)" :key="index1"   @click="goTo('/huizhan_list',{p_id:item1.p_id,id:item1.id})">
                            <img :src="item1.banner_url" alt="">
                            <p>{{item1.name}}</p>
                        </div>
                    </div>
                    <div class="more" @click="goTo('/huizhan_state',{key:index})">
                        <p>更多</p>                         
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="gjhzbg">
            <img :src="gg[6]?gg[6].pic_url:require('../../../assets/images/gjhzbg.png')" alt="">
        </div>

        <div class="kuajing-bj">
            <img :src="gg[7]?gg[7].pic_url:require('../../../assets/images/kjdspt-bj2.png')" alt="">
            <div class="kuajing-p">
                <div class="kuajing-p1">{{gg[7]?gg[7].title:''}}</div>
                <div class="kuajing-p2">{{gg[7]?gg[7].content:''}}</div>
                <div class="kuajing-bt" @click="goTo('contact_us')">联系我们</div>
            </div>
        </div>



    </div>
</template>

<script>
    import banner from '../../../components/banner.vue';
    import {getplatexts ,getcountrys,getplateAds } from '../../../api/index';
    import {mapState, mapMutations, mapActions} from 'vuex'  
    export default {
        data() {
            return {
                ggList:[],  
                guojiaList:[],
                gg:[]
            }
        },
        components:{
            banner
        },
        computed: {
            ...mapState(['isLogin'])         
        },
        created() {
            this.getplateAds(); 
            this.getplatexts()
            this.getcountrys()
        },
        methods: {
            // 广告
            getplateAds(){
                getplateAds({
                    plate_id:8,
                    seat:0
                }).then((res)=>{
                    // console.log(res)
                    if(res.code==200){
                        this.gg=res.data
                    }
                })
            },
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            // 跳转外链
            goUrl(url){
                // if ( !this.isLogin) {                    
                //     this.$confirm('请您先登录注册会员','温馨提示', {
                //         confirmButtonText: '去登录',
                //         }).then(() => {
                //             this.$router.push({
                //                 path:'/login'
                //             })
                //         }).catch(() => {
                //             this.$message({
                //                 type: 'info',
                //                 message: '请登录后再操作'
                //             });
                //     });
                //     return;
                // }
                window.open(url) 
            },
            // 获取推荐
            getplatexts(){
                // this.tuijianLoading=true
                getplatexts({
                    plate_id:8,
                    recom:1,
                }).then((res)=>{
                    setTimeout(() => {
                        this.tuijianLoading=false
                    }, 200);
                    // console.log(res)
                    if (res.code==200) {
                        this.ggList=res.data.data;
                    }
                })
            },
            //获取国家列表
            getcountrys(){
                getcountrys().then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.guojiaList=res.data
                    }
                })
            }
            
        },
        
    }
</script>

<style lang="less" scoped>
    .page{overflow: hidden;}
    .tuijian-wrap{
        margin: auto;
        .tuijian-list{
            margin-top: 40px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .tuijian-item{
                width: 18%;
                margin-right: 2.5%;
                padding: 0 10px;
                border: 1px solid #E8E8E8;
                background: #F7F7F7;
                box-sizing: border-box;
                margin-bottom: 35px;
                border-top: 2px solid #0071DB;
                .tuijian-top{
                    padding: 12px 10px;
                    border-bottom: 1px solid #E8E8E8;
                    .tuijian-text{
                        text-align: center;
                        .tuijian-p{color: #000000;font-size: 24px;font-weight: 300;margin-bottom: 6px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
                        .tuijian-p1{color: #000000;font-size: 15px;font-weight: 300;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
                    }
                }
                .tuijian-bottom{
                    padding: 15px 0;
                    display: flex;justify-content: space-between;
                    .tuijian-bt{width: 45%;height: 30px;line-height: 30px;text-align: center;
                        font-size: 14px;font-weight: 400;border-radius: 14px;cursor: pointer;                        
                        &:nth-child(1){border: 1px solid #0071DB;background: #fff;color: #000;}
                        &:nth-child(2){border: 1px solid #FFFFFF;background: #fff;color: #666;}
                        &:hover{background: #0071DB;color: #fff;}
                    }
                }
                &:hover{
                    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
                }
                &:nth-child(5n+0){margin-right: 0;}

            }
        }
        
        .pingtai{
            .pingtai-text{
                margin: 25px auto 45px auto;text-align:center;
                .pingtai-p{color: #333333;font-size: 35px;}
                .pingtai-p1{color:#666666;font-size: 16px;font-weight: 300;margin-top: 15px;}
            }
            .pingtai-list{
                display: flex;justify-content: space-between;
                .pingtai-item{
                    width: 17%;border: 1px solid #F3F3F3;padding: 30px;box-sizing: border-box;text-align: center;background: #F7FAFF;
                    img{width: 67px;height: 67px;margin: auto;}
                    .pingtai-item-p{color: #000000;font-size: 18px;margin: 20px 0;}
                    .pingtai-item-p1{color: #666666;font-size: 14px;}
                }
            }
        }


        .state-wrap{
            margin-top: 10px;overflow: hidden;padding:0 20px;padding-bottom:40px;
            .state{
                 margin:35px 0;display: flex;align-items: center;justify-content: space-between;
                .state-list{
                    display: flex;align-items: center;flex: 1;
                    .state-item-text{color: #000;font-size: 16px;font-weight: bold;width: 130px;}
                    .state-item{
                        display: flex;align-items: center;font-size:16px;color:#000;cursor: pointer;
                        img{width: 28px;margin-right: 10px;}
                        p{width: 120px;}
                        &:hover{color: #FF7048;text-decoration:underline;}
                    }
                }
                .more{
                    display: flex;align-items: center;color: #FF7048;flex-shrink: 0;cursor: pointer;font-size: 16px;
                    p{margin: 0 5px 1px 0;}
                }
            }

        }
        
    }
    .gjhzbg{
        width: 100%;height: auto;
        img{width: 100%;}
    }

    .kuajing-bj{
        width: 100%;position: relative;
        img{width: 100%;height: auto;}
        .kuajing-p{
            width: 100%;position: absolute;top:100px;text-align:center;
            .kuajing-p1{color: #333333;font-size: 35px;margin-bottom: 10px;}
            .kuajing-p2{color: #000000;font-size: 18px;font-weight: 300;}
            .kuajing-bt{
                width: 141px;height: 36px;background: #0071DB;
                border-radius: 15px;line-height: 36px;text-align: center;
                color: #fff;font-size: 17px;font-weight: 300;
                margin: 30px auto;cursor: pointer;
            }
        }
    }

</style>